import React, { memo } from 'react';
import cn from 'classnames';
import differenceInDays from 'date-fns/differenceInDays';
import formatDistanceToNowStrict from 'date-fns/formatDistanceToNowStrict';

import { differenceInYears, format } from 'date-fns';
import { getFormattedDuration } from 'utils/format';
import styles from './DatePosted.module.scss';

export interface DatePostedProps {
  /** Custom class name */
  className?: string;
  /** Custom style */
  style?: Record<string, unknown>;
  created_at: string;
}

export const DatePosted: React.FC<DatePostedProps> = memo(
  ({
    className = '', // custom class name
    style, // custom style
    created_at,
  }: DatePostedProps) => {
    const contentClassNames = cn(
      {
        [styles.DatePosted]: true,
      },
      className
    );
    const diffInDays = differenceInDays(new Date(), new Date(created_at));
    const diffInYears = differenceInYears(new Date(), new Date(created_at));

    const getCreatedAt = () => {
      if (diffInDays === 0 || created_at === null) return 'Today';
      else if (diffInDays === 1) return 'Yesterday';
      else if (diffInDays > 1 && diffInDays <= 30)
        return getFormattedDuration(
          formatDistanceToNowStrict(new Date(created_at), { addSuffix: true })
        );
      else if (diffInDays > 30 && diffInDays <= 90)
        return getFormattedDuration(
          formatDistanceToNowStrict(new Date(created_at), { addSuffix: true })
        );
      else if (diffInYears < 1) return format(new Date(created_at), 'MMM d');
      else if (diffInYears >= 1)
        return format(new Date(created_at), 'MMM d, yyyy');
    };

    const datePosted = getCreatedAt();

    return datePosted ? (
      <p className={contentClassNames} style={style}>
        {datePosted}
      </p>
    ) : null;
  }
);

DatePosted.displayName = 'DatePosted';
